<script>
    import IconButton from './IconButton.svelte';
    import FaFacebook from 'svelte-icons/fa/FaFacebook.svelte';
    import FaGoogle from 'svelte-icons/fa/FaGoogle.svelte';
</script>

<footer class="py-6 text-white bg-gray-800">
    <div class="grid grid-rows-3 px-16 py-6 text-center md:text-right md:grid-cols-3 justify-items-center md:max-h-24">
        <div class="mb-8 md:mb-0 md:ml-8 md:mr-24">
            <img class="max-w-[9rem] rounded-full shadow-md" src="img/icon.webp" alt="eaz2load icon" />
        </div>
        <div>
            <p>20, rue Begin <br /> Ile-Bizard (Quebec) <br /> H9C 2X9</p>
            <div class="mt-3 space-x-2">
                <IconButton target={'_blank'} href={'https://www.facebook.com/eazy2loadcom-244595679223728'}>
                    <FaFacebook />
                </IconButton>
                <IconButton target={'_blank'} href={'https://g.page/r/CVwLIhwPSr2JEAI'}>
                    <FaGoogle />
                </IconButton>
            </div>
        </div>
        <p>
            Email: <a class="link" href="mailto:info@eazy2load.com">info@eazy2load.com</a><br />
            Phone: 514-626-9312 <br />
            Fax: 514-626-9442 <br />
        </p>
    </div>
    <p class="mt-4 font-bold text-center md:mt-24">© Copyright 2021 eazy2load.com - All Rights Reserved</p>
</footer>
