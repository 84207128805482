<script lang="ts">
    export let icon: string = '';
    export let iconAlt: string = '';
    export let heading: string = '';
    export let subHeading: string = '';
</script>

<div class="inline-block px-6 py-12 text-center transition-shadow bg-gray-100 shadow-md w-96 md:w-64 rounded-2xl">
    <div class="flex flex-col place-items-center">
        <div class="max-h-32 aspect-square">
            <img src={icon} alt={iconAlt} />
        </div>
        <h3 class="mt-6 text-lg font-bold uppercase">{heading}</h3>
        <p class="mt-6 text-md">{subHeading}</p>
    </div>
</div>
