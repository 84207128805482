<script lang="ts">
    export let offset = 10;

    const hiddenClass: string = 'bg-transparent text-white';
    const visibleClass: string = 'bg-yellow-300 text-blue-500';

    let visibility: string = hiddenClass;
    let y: number = 0;

    function updateClass(y) {
        if (y > offset) {
            return visibleClass;
        }

        return hiddenClass;
    }

    $: visibility = updateClass(y);
</script>

<svelte:window bind:scrollY={y} />

<header class="transition-colors fixed {visibility} z-10  flex flex-row w-screen h-24 px-20 place-items-center">
    <a href="/" class="text-3xl font-bold ">eazy2load</a>
    <div class="grow" />
    <!-- <a href="#contact" class="font-bold">Contact Us</a> -->
</header>
