<script lang="ts">
    import GlobalCSS from '../../css/GlobalCSS.svelte';

    import { fly } from 'svelte/transition';

    import type { SnackBarController } from './SnackbarController.type';
    import { delay } from '../../utility';

    export let showBar = false;

    export const controller: SnackBarController = {
        async trigger(duration: number = 500, message: string = 'Success!'): Promise<void> {
            showBar = true;

            this.message = message;
            await delay(duration);

            showBar = false;
        },
        message: 'Success!',
    };
</script>

<GlobalCSS />

<div class="fixed bottom-0 flex w-screen">
    {#if showBar}
        <div transition:fly={{ y: 200, duration: 200 }} class="px-8 py-3 mx-auto mb-16 text-base font-medium transition-opacity duration-300 bg-white rounded-md shadow-md place-items-center">{controller.message}</div>
    {/if}
</div>
