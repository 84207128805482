<script lang="ts">
  //workaround for lack of support in plesk...
  if (location.protocol !== 'https:') {
    location.replace(`https:${location.href.substring(location.protocol.length)}`);
  }

  import GlobalCSS from './css/GlobalCSS.svelte';
  import Header from './components/Header.svelte';
  import AnchorButton from './components/AnchorButton.svelte';
  import IconButton from './components/IconButton.svelte';
  import InfoCard from './components/InfoCard.svelte';
  import Footer from './components/Footer.svelte';
  import FormButton from './components/FormButton.svelte';
  import FaInfoIcon from 'svelte-icons/fa/FaInfo.svelte';

  import Snackbar from './components/snackbar/Snackbar.svelte';
  import type { SnackBarController } from './components/snackbar/SnackbarController.type';

  import { Form, Input } from 'sveltejs-forms';
  import { Jellyfish } from 'svelte-loading-spinners';
  import { isEmail, normalizeEmail } from 'validator';

  let snackbarController: SnackBarController;

  async function send({ detail: { values, setSubmitting, resetForm } }) {
    if (!isEmail(values['email'])) {
      snackbarController.trigger(2000, 'Invalid Email...');
      setSubmitting(false);
      return;
    }

    const msg: object = {
      to: 'info@eazy2load.com', // Change to your recipient
      from: 'form@eazy2load.com', // Change to your verified sender
      subject: `New Form Entry from ${normalizeEmail(values['email'])}`,
      text: values['message'],
      html: values['message'],
    };

    try {
      var response = await fetch('https://eazy2load-api.azurewebsites.net/email', {
        method: 'POST',
        body: JSON.stringify(msg),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      snackbarController.trigger(2000, 'Message failed to send... Please try again later.');
    } finally {
      setSubmitting(false);
    }

    if (response.ok) {
      snackbarController.trigger(2000, 'Message sent!');
      resetForm();
    }
  }
</script>

<GlobalCSS />

<Header />

<main>
  <!-- overlay quick contact button -->
  <div class="fixed z-50 bottom-8 right-6">
    <IconButton href={'#contact'}>
      <FaInfoIcon />
    </IconButton>
  </div>

  <div class="flex flex-col items-center h-screen grid-cols-3 text-white bg-fixed bg-center bg-cover md:grid place-content-center" style="background-image: url(img/landing.webp);">
    <div class="absolute w-full h-full bg-black bg-opacity-40" />
    <h1 class="text-5xl font-bold md:col-start-3 z-5">Easy to Load Solutions</h1>
    <h2 class="mt-5 text-2xl md:col-start-3 z-5">For all pick-ups and light duty trucks</h2>
    <AnchorButton exClass={'col-start-3'} href={'#learn-more'}>Learn More</AnchorButton>
  </div>

  <div id="learn-more" class="flex flex-col items-center gap-12 py-16 md:flex-row place-content-center">
    <InfoCard icon={'img/cards/light_icon.webp'} heading={'Only 200 Lbs'} iconAlt={'icon of a balance'} subHeading={'The aluminum box is at least 50% lighter than the original steel box. It weighs only 200 lbs!'} />
    <InfoCard icon={'img/cards/cargo_icon.webp'} heading={'More Cargo'} iconAlt={'icon of a balance'} subHeading={'Since the box is lighter than the regular steel box, there is a higher loading capacity.'} />
    <InfoCard icon={'img/cards/fuel_icon.webp'} heading={'More fuel efficient'} iconAlt={'icon of a balance'} subHeading={'Less weight equals less fuel consumption! You will get a better gaz / mileage ratio.'} />
  </div>

  <div class="py-12 bg-gray-700">
    <iframe class="w-[75%] mx-auto md:max-w-3xl aspect-video" src="https://www.youtube.com/embed/nvYpkE4HCvI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
  </div>

  <div id="contact" class="flex grid-cols-2 p-12 px-12 bg-center bg-cover md:grid justify-items-center md:justify-items-end" style="background-image: url(img/landing.webp);">
    <div class="col-start-2 p-12 text-gray-800 bg-white rounded-md shadow-md w-full md:w-[75%]">
      <Form on:submit={send} let:isSubmitting>
        <div class="absolute transition-opacity pointer-events-none top-0 bottom-0 left-0 right-0 flex flex-col text-center bg-white {isSubmitting ? 'opacity-100' : 'opacity-0'} z-5 place-items-center place-content-center">
          <p class="mb-6 text-lg font-bold uppercase">Sending</p>
          <Jellyfish color={'#ef4444'} size={100} />
        </div>
        <h2 class="text-lg font-bold text-center uppercase">Contact Us</h2>
        <Input disabled={isSubmitting} class="w-full p-2 mt-6 border-2 border-gray-300 rounded-md" name="email" placeholder="Email" />
        <Input disabled={isSubmitting} class="w-full h-24 p-2 mt-3 border-2 border-gray-300 rounded-md resize-none" name="message" placeholder="Message" multiline />
        <FormButton type="submit" disabled={isSubmitting}>Send</FormButton>
      </Form>
    </div>
  </div>

  <Snackbar bind:controller={snackbarController} />
</main>

<Footer />

<!-- svelte-ignore css-unused-selector -->
<style lang="postcss">
  :global(.sveltejs-forms) {
    position: relative;
  }
</style>
