<script lang="ts">
    export let disabled: boolean = false;
    export let exClass: string = '';
    export let href: string = 'https://www.google.com';
    export let type: string = '';
</script>

<button {href} {type} {disabled} class="{exClass} font-bold uppercase px-5 py-3 mt-5 text-center text-white transition-all duration-150 bg-red-500 rounded-md  hover:bg-red-400 hover:shadow-none z-5 w-full">
    <slot />
</button>
